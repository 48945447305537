import isEmpty from 'lodash/isEmpty';
import { BUSINESS_TRIP_DESTINATION } from '@/constants/business-trip2';

const getDestinationCity = (block) => {
  if (isEmpty(block) || !block.region) return null;
  if (block.region.abbrev === BUSINESS_TRIP_DESTINATION.cityAbbrev) {
    return block.region;
  }
  if (block.area?.abbrev === BUSINESS_TRIP_DESTINATION.cityAbbrev) {
    return block.area;
  }

  return isEmpty(block.settlement) ? block.city : block.settlement;
};

export default getDestinationCity;
